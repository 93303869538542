<template>
    <TabGroup as="div" class="mt-2">
        <div class="border-b border-gray-200">
            <TabList class="-mb-px flex space-x-8 px-4">
                <Tab as="template" v-for="category in navigation.categories"
                     :key="category.name" v-slot="{ selected }">
                    <button
                        :class="[selected ? 'text-indigo-600 border-indigo-600' : 'text-gray-900 border-transparent', 'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium']">
                        {{ category.name }}
                    </button>
                </Tab>
            </TabList>
        </div>

        <TabPanels as="template">
            <TabPanel v-for="category in navigation.categories"
                      :key="category.name"
                      class="space-y-10 px-4 pt-10 pb-8">
                <div class="grid grid-cols-2 gap-x-4">
                    <div v-for="item in menu_items.featured" :key="item.name"
                         class="group relative text-sm">
                        <div
                            class="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                            <img :src="asset(item.imageSrc)" :alt="item.imageAlt"
                                 class="object-cover object-center"/> ???
                        </div>
                        <jet-nav-link :href="item.href"
                                      class="mt-6 block font-medium text-gray-900">
                                                    <span class="absolute inset-0 z-10"
                                                          aria-hidden="true"/>
                            {{ item.name }}
                        </jet-nav-link>
                        <p aria-hidden="true" class="mt-1 text-gray-500">Koop nu</p>
                    </div>
                </div>
                <!-- MOBIEL!!!! -->
                <div class="space-y-0 border-gray-200 py-4 px-0 border-t">
                    <HeaderSearch :website="website" :page="page" @headerSearch="headerSearch"></HeaderSearch>
                </div>
                <h5 class="font-bold text-black !mt-1 border-t pt-4">
                    Top Categorieën</h5>
                <div
                    class="row-start-1 grid grid-cols-2 gap-y-2 gap-x-8 text-sm !mt-4 pb-6">
                    <div
                        v-for="(category,key) in website.categories"
                        :key="category.url">
                        <jet-nav-link :href="'/'+key" :id="`${category.name}-heading`"
                                      class="font-medium text-gray-900">
                            {{
                                category.name
                            }}
                        </jet-nav-link>
                    </div>
                </div>

                <h5 class="font-bold text-black !mt-1 border-t pt-4">MENU</h5>
                <div
                    class="row-start-1 grid grid-cols-2 gap-y-2 gap-x-8 text-sm !mt-4">
                    <div v-for="page in menu_items.header_right" :key="page.id">
                        <jet-nav-link :href="'/'+page.url" class="text-black clear-both">{{page.name}}</jet-nav-link>
                    </div>
                </div>
<!--        <div class="!mt-0">-->
<!--                <label class="inline-flex relative items-center cursor-pointer">-->
<!--                    <input type="checkbox" value="" class="sr-only peer"    @change="switchTheme"-->
<!--                           :checked="userTheme==='dark'">-->
<!--                    <div class="w-12 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4-->
<!--                                                                         peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700-->
<!--                                                                         peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']-->
<!--                                                                         after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300-->
<!--                                                                          after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>-->
<!--                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" v-if="userTheme!=='dark'">🌙</span>-->
<!--                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" v-if="userTheme!=='light'">☀</span>-->

<!--                </label>-->
<!--        </div>-->
            </TabPanel>
        </TabPanels>
    </TabGroup>

</template>

<script>
import JetNavLink from '@/Jetstream/NavLink.vue'
import {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
} from '@headlessui/vue'
import HeaderSearch from  "@/Pages/Admin/Page/Templates/Partials/HeaderSearch.vue";


export default {
    components: {
        TabList,
        Tab,
        JetNavLink, TabPanels, TabPanel, TabGroup,HeaderSearch,
    },
    props: {
        menu_items: Object,
        website: Object,
        navigation: Object,
        page:Object,
        userTheme:String
    },
    data() {
        return {};
    },

    methods: {
        headerSearch(search){
            this.$emit('headerSearch',search);
        },
        switchTheme(){
            this.$emit('switchTheme');
        }
    }
}


</script>
